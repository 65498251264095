import {  qspost } from '@/config/axios';

//获取列表数据
export const getGameTypeList = data => qspost("request?", data ,"getGameTypeList");

//禁用、启用分类
export const useGameType = data => qspost("request?", data ,"useGameType");

// 编辑
export const updateGameType = data => qspost("request?", data ,"updateGameType");

// 添加
export const addGameType = data => qspost("request?", data ,"addGameType");

// 删除
export const del = data => qspost("request?", data ,"delGameType");